<template>
  <backoffice-container>
    <b-card class="mt-2">
        <div class="align-items-center d-flex" style="margin: 30px 0;">
          <h4 class="m-0 text-dark">
            {{ $t('backoffice.staff.title') }}
          </h4>
        </div>
      <members-content type="backofficeStaff"/>
    </b-card>
  </backoffice-container>
</template>

<script>
// import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
// import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
//import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
//import { validatorEmailValidator } from '@/@core/utils/validations/validators';
// import Ripple from 'vue-ripple-directive';
//import Placeholder from "@/assets/images/placeholders/light/general-placeholder.svg";
import MembersContent from './components/MembersContent.vue';

export default {
  name: 'StaffList',
  components: {
    MembersContent,
    BackofficeContainer,
  },
  // mixins: [CardWidgetMixin, ToastNotificationsMixin],
  props: {
    //
  },
  data() {
    return {
    //   type: 'communityMembers',
    };
  },
  computed: {
    //
  },
};
</script>
